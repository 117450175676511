import React, { useEffect, useState } from 'react';
import Layout from '@components/common/Layouts/Landing';
import { makeGetRequest } from '@helpers/requests';
import { HELP_ARTICLES, USER_AVATAR } from '@helpers/api';
import { HelpArticle, HelpArticles } from '@models/helpArticle';
import { useSnackbar } from '@components/common/Snackbar';
import { Alert, Avatar, AvatarGroup, Box, Button, Grid, Typography } from '@mui/material';
import { DetailedMetaItem } from '@models/meta';
import { Link } from 'gatsby';
import { useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import styles from './styles';
import { useTheme } from '@mui/material';
import HelpPanel from '@components/Landing/Help/common/HelpPanel';
import { HelpCategories } from '@models/helpCategory';
import Header from '@components/Landing/Help/common/Header';
import CardThumbnail from '@components/Landing/Pricing/common/CardThumbnail';
import { formatDistanceStrict, parseISO } from 'date-fns';
import Breadcrumbs from '@components/common/Breadcrumbs';

const Article: React.FC<{ article: HelpArticle; categories: HelpCategories }> = ({
	article,
	categories,
}) => {
	const user = useSelector(({ user }) => user);
	const theme = useTheme();
	const [articles, setArticles] = useState<HelpArticles | null>(null);
	const [openSnackbar] = useSnackbar();

	useEffect(() => {
		(async () => {
			try {
				const { data } = await makeGetRequest(HELP_ARTICLES, {
					perPage: 1000,
					pageNum: 1,
				});
				setArticles(data?.results);
			} catch (error) {
				if (error === 'cancelled') return;
				console.log({ error });
				openSnackbar(
					error?.errorMessage ?? `An error occurred attempting to load article.`,
					'error'
				);
			}
		})();
	}, []);

	const createMarkup = () => {
		return { __html: article?.html };
	};

	return (
		<Layout>
			<div css={styles(theme)}>
				<Header
					type="article"
					title={article.title}
					description={article.description}
					article={article}
				/>

				<HelpPanel
					categories={categories}
					article={article}
					tabValue={article?.category?.shortName}
				>
					<Breadcrumbs
						breadcrumbs={[
							{ path: '/help', name: 'Help Centre' },
							{
								path: `/help/${article?.category?.shortName}`,
								name: article?.category?.title,
							},
							{ name: article?.title },
						]}
					/>
					<Grid container direction="row" justifyContent="center">
						<Grid item>
							<Box
								sm={12}
								md={6}
								my={4}
								style={{
									maxWidth: '800px',
									margin: `${theme.spacing(2)} auto`,
									padding: theme.spacing(4),
									backgroundColor:
										theme.palette.mode === 'light'
											? theme.palette.common.white
											: theme.palette.grey['800'],
									color:
										theme.palette.mode === 'light'
											? theme.palette.common.black
											: theme.palette.common.white,
								}}
							>
								<div
									className="article-content"
									dangerouslySetInnerHTML={createMarkup()}
								/>
							</Box>
						</Grid>

						<Grid
							container
							item
							lg={4}
							md={9}
							sm={10}
							sx={{
								maxWidth: '800px',
								margin: 'auto',
								marginTop: 0,
								marginBottom: theme.spacing(2),
								alignContent: 'flex-start',
							}}
						>
							<Grid
								container
								direction="row"
								spacing={2}
								sm={12}
								mx={2}
								sx={{ alignContent: 'flex-start' }}
							>
								<Grid item sm={10}>
									<Box mt={2}>
										<Typography variant="h3">Read More</Typography>
									</Box>
								</Grid>
								{articles
									?.filter(
										(result) =>
											result?._id !== article?._id &&
											result?.category?._id === article?.category?._id
									)
									?.map((article, index) => (
										<Grid item lg={12} sm={12} xs={12} key={index}>
											<CardThumbnail article={article} />
										</Grid>
									))}
							</Grid>

							<Grid
								container
								mx={4}
								my={2}
								style={{
									display: 'flex',
									justifyContent: 'space-between',
									alignItems: 'center',
									padding: `${(theme.spacing(2), 0)}`,
								}}
							>
								{'Last updated '}
								{!!article?.meta?.lastUpdated &&
									formatDistanceStrict(
										parseISO(article?.meta?.lastUpdated?.timestamp),
										new Date()
									)}
								{' ago'}
								<AvatarGroup max={4}>
									{(article?.meta?.updated as DetailedMetaItem[])
										?.reduce(
											(acc: DetailedMetaItem[], curr) =>
												acc?.some(
													(user) => user?.user?._id === curr?.user?._id
												)
													? acc
													: [...acc, curr],
											[]
										)
										?.map((user, index) => {
											return (
												<Avatar
													alt={`${
														user?.user?.firstName || 'User'
													}'s Avatar`}
													src={USER_AVATAR(user?.user?._id)}
													key={index}
												/>
											);
										})}
								</AvatarGroup>
							</Grid>
						</Grid>
					</Grid>
				</HelpPanel>
			</div>
		</Layout>
	);
};

export default Article;
