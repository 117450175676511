import React from 'react';
import { graphql } from 'gatsby';
import Article from '../../views/Help/Category/Article';
import { useSnackbar } from '@components/common/Snackbar';
import { navigate } from 'gatsby-link';
const helpArticleTemplate = ({ data }) => {
	const [openSnackbar] = useSnackbar();

	if (!data?.helpArticle?.category?._id) {
		openSnackbar(`An error occurred attempting to load article.`, 'error');
		navigate('/support');
	}

	const {
		helpArticle,
		allHelpCategory: { edges: categories },
	} = data;
	return (
		<Article
			article={helpArticle}
			categories={categories?.map((category) => ({ ...category?.node }))}
		/>
	);
};
export default helpArticleTemplate;

export const query = graphql`
	query($id: String) {
		helpArticle(_id: { eq: $id }) {
			title
			shortName
			_id
			html
			meta {
				updated {
					timestamp
				}
				lastUpdated {
					timestamp
				}
			}
			category {
				_id
				title
				shortName
			}
		}
		allHelpCategory {
			edges {
				node {
					_id
					title
					shortName
					description
				}
			}
		}
	}
`;
